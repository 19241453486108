<template>
  <b-container fluid>
    <Teleport to="#header-buttons">
      <div>
        <b-button
          id="show-btn"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="openVenueModal"
        >
          Add Venue
        </b-button>
        <b-button
          v-if="!userRole===AccountTypes.ADMIN"
          id="device-btn"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="ml-2"
          variant="primary"
          @click="gotoCreateDevice"
        >
          Add Screen
        </b-button>
      </div>
    </Teleport>
    <b-skeleton
      v-if="statsLoader"
      type="input"
      class="mb-2"
    />
    <b-row
      v-else
    >
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="MapIcon"
          :statistic="allVenuesData ? allVenuesData.totalVenues : 0"
          statistic-title="Total Venue(s)"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="MaximizeIcon"
          color="success"
          :statistic="allVenuesData ? allVenuesData.totalDevices : 0"
          statistic-title="Total Screens"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="CalendarIcon"
          color="danger"
          :statistic="allVenuesData ? allVenuesData.totalPmpCampaigns : 0"
          statistic-title="Total Campaigns"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="AirplayIcon"
          color="warning"
          :statistic="allVenuesData ? allVenuesData.totalAds : 0"
          statistic-title="Total Ads"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="6"
        sm="6"
      >
        <b-skeleton-img
          v-if="statsLoader"
          height="190px"
        />
        <b-card
          v-else
          class="card-congratulation-medal"
          @click="pushToVenue"
        >
          <div class="d-flex justify-content-start pb-1">
            <h5>Top Rated Venue</h5>
          </div>
          <b-card-text
            class="font-large-1 font-weight-bold"
          >
            <div
              class="cursor-pointer text-primary cursor-pointer zoom-hover text-truncate pb-1"
              style="max-width: 300px;"
            >
              <span
                v-b-tooltip.hover
                :title="allVenuesData ? allVenuesData.topRatedVenue ? allVenuesData.topRatedVenue.venue_name : '--' : '--'"
              >
                {{ allVenuesData ? allVenuesData.topRatedVenue ? allVenuesData.topRatedVenue.venue_name : '--' : '--' }}
              </span>
            </div>
          </b-card-text>
          <div class="d-flex flex-column justify-content-between align-items-start ">
            <span class="d-flex align-items-center">
              <p class="mb-75 mt-1 font-large-1 font-weight-bolder pr-1 pb-1">
                {{ allVenuesData ? allVenuesData.topRatedVenue? allVenuesData.topRatedVenue.campaign_count_on_this_venue : '--' : '--' }}
              </p> <span v-if="allVenuesData ? allVenuesData.topRatedVenue ? allVenuesData.topRatedVenue.campaign_count_on_this_venue : true : true">Campaigns on this venue</span>
            </span>
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              View Venue
            </b-button> -->
            <b-img
              :src="require('@/assets/images/images/badge.svg')"
              class="congratulation-medal"
              alt="Medal Pic"
            />
          </div>
        </b-card>
      </b-col>
      <b-col
        lg="6"
        sm="6"
      >
        <b-skeleton-img
          v-if="statsLoader"
          height="190px"
        />
        <b-card
          v-else
          class="card-congratulation-medal"
          @click="pushToTopRatedScreen"
        >
          <div class="d-flex justify-content-start pb-1">
            <h5>Top Rated Screen</h5>
          </div>
          <b-card-text
            class="font-large-1 font-weight-bold"
          >
            <div
              class="cursor-pointer text-primary cursor-pointer zoom-hover text-truncate pb-1"
              style="max-width: 300px;"
            >
              <span
                v-b-tooltip.hover
                :title="allVenuesData ? allVenuesData.topRatedScreen ? allVenuesData.topRatedScreen.name : '--' : '--'"
              >
                {{ allVenuesData ? allVenuesData.topRatedScreen ? allVenuesData.topRatedScreen.name : '--' : '--' }}
              </span>
            </div>
          </b-card-text>
          <div class="d-flex flex-column justify-content-between align-items-start ">
            <span class="d-flex align-items-center">
              <p class="mb-75 mt-1 font-large-1 font-weight-bolder pr-1 pb-1">
                {{ allVenuesData ? allVenuesData.topRatedScreen? allVenuesData.topRatedScreen.ad_count_on_this_screen : '--' : '--' }}
              </p>
              <span v-if="allVenuesData ? allVenuesData.topRatedScreen : false">Ads on this screen</span>
            </span>
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              View Screen
            </b-button> -->
          </div>
          <b-img
            :src="require('@/assets/images/images/personalization.svg')"
            class="congratulation-medal-personalization"
            alt="Medal Pic"
            height="150px"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        class="px-0"
      >
        <b-card
          no-body
          class="mt-lg-0 mt-2"
          title="All Venues"
        >
          <AllVenues
            ref="allVenues"
          />
        </b-card>
      </b-col>
    </b-row>
    <AddVenue
      ref="add-venue"
      @reloadVenues="$refs.allVenues.loadVenue()"
    />
  </b-container>
</template>
<script>

import {
  BContainer, BRow, BCol, BCard, BButton,
  BSkeletonImg,
  BSkeleton,
  BCardText,
  BImg,
  VBTooltip,
} from 'bootstrap-vue'
import Teleport from 'vue2-teleport'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import AllVenues from '@/components/venues/AllVenues.vue'
import AddVenue from '@/components/venues/AddVenue.vue'
import AccountTypes from '@/common/enums/accountTypeEnum'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BButton,
    AllVenues,
    AddVenue,
    Teleport,
    BSkeletonImg,
    BSkeleton,
    BCardText,
    BImg,
    StatisticCardHorizontal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      AccountTypes,
      allVenuesData: null,
      statsLoader: false,
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
    isUserAdmin() {
      return this.$store.getters['user/getUserRole'] === AccountTypes.ADMIN
    },
  },
  async mounted() {
    await this.getAllVenueStats()
  },
  methods: {
    openVenueModal() {
      this.$refs['add-venue'].show()
    },
    gotoCreateDevice() {
      this.$router.push({
        name: 'create-device',
      })
    },
    async getAllVenueStats() {
      try {
        this.statsLoader = true
        let response
        if (this.isUserAdmin) {
          response = await this.$axios.get('/admin/pmp-ads/dashboard-statistics')
        } else {
          response = await this.$axios.get('/pmp-ads/dashboard-statistics')
        }
        this.allVenuesData = response.data.data
      } catch (error) {
        console.error(error.message)
        showToast('Venue Stats', error.message, 'danger')
      } finally {
        this.statsLoader = false
      }
    },
    pushToTopRatedScreen() {
      this.$router.push(`/venues/screen/${this.allVenuesData.topRatedScreen.id}`)
    },
    pushToVenue() {
      this.$router.push(`/devices/${this.allVenuesData.topRatedVenue.id}`)
    },
  },
}
</script>

<style>
.congratulation-medal{
    position: absolute;
    right: 0 !important;
    top: 0 !important;
    bottom: 100px !important;
    left: 25rem !important;
}
.congratulation-medal-personalization{
    position: absolute !important;
    right: 0 !important;
    top: 1.25rem !important;
    bottom: 0rem !important;
    left: 15rem !important;
}
.zoom-hover {
    padding-left: 5px;
    transition: transform 0.2s ease-in-out !important;
    transform-origin: left center;
  }

  .zoom-hover:hover {
    transform: scale(1.1) !important;
  }
</style>
